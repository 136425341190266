.first-section-container {
    background-color: rgb(241, 222, 180);
    height: 93vh;
    display: flex;
    align-items: center;
}

.first-section {
    align-items: center;
    margin: 0;
    padding: 5% 3%;
}

.first-col {
    text-align: center;
}

.first-col>h1 {
    padding-top: 0%;
    font-family: 'Kaushan Script',
        cursive;
    font-size: 4.5em;
}

.first-col>h2 {
    font-family: 'Kaushan Script',
        cursive;
}

.second-col {
    height: auto;
}

.second-col>img {

    max-width: 80%;

    max-height: 80%;
    padding-top: 5%;
}

.second-col>img:hover {
    cursor: pointer;
    transform: scale(1.0)
}

.second-section {
    background-color: #fff;
    color: #000;
    width: 95%;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 2.5%;
    border-radius: 20px;
    padding: 0% 7% 3% 7%;
    font-family: 'Montserrat';
}

.third-section-container {
    width: 95%;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}

.icon-theme {
    max-width: 25%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.button-container {
    display: flex;
    justify-content: center;

    padding-top: 3%;
    padding-bottom: 3%;
}

.title-container>h2 {
    padding-top: 0;
    text-align: center;
}

.single-theme {
    text-align: center;
    font-family: 'Montserrat';
}

.single-theme>h4 {
    font-weight: normal;
}

@media screen and (max-width: 768px) {


    .first-section {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        padding-bottom: 15%;
    }

    .first-col {
        padding-bottom: 3%;
    }

    .second-col {

        padding-bottom: 3%;
    }

    .first-col>h1 {
        font-size: 2em;
    }

    .first-col>h2 {
        font-size: 1.5em;
    }

    .themes {
        padding-top: 3%;
    }

    .single-theme {
        padding-bottom: 6%;
    }
}
