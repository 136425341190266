.list-container {
    margin: 0 auto;
    padding:0
}

.list {
    margin: 1% auto;
    justify-content: center;
}

@media screen and (max-width: 600px) {

    .list-container {
        display: flex;
        justify-content: center;
        padding-left: 0%;
        padding-right: 4%;
    }

}